import { rgba as originalRGBA } from 'polished';

const rgba = (color: string | number, alpha?: number): string => {
  return !color || color === 'transparent' ? 'transparent' : originalRGBA(color, alpha ?? 1);
};

export { rgba };

export default {
  accent: {
    default: '#2570D1',
  },
  border: {
    default: '#D0D7D9',
  },
  background: {
    contrast: '#131D35',
    accent: '#F4F8FD',
    highlight: '#F6F7F8',
  },
  pink: {
    50: '#FFE1E5',
    100: '#FFF3F5',
    200: '#FFD7DC',
    500: '#E8308A',
  },
  gray: {
    50: '#1C2629',
    100: '#A1ABB2',
    200: '#D5D9DE',
    300: '#5E6A75',
    400: '#809999',
    500: '#123233',
    600: '#2C292A',
    700: '#0e1125',
    800: '#003333',
    900: '#104040',
  },
  blue: {
    400: '#E3EDF3',
  },
  green: {
    100: '#EEF6EB',
    300: '#89DCB4',
    400: '#C3E5C6',
    500: '#46633C',
  },
  red: {
    100: '#FEE2E2',
    500: '#EC0003',
  },
};
